/**
 * Toggles password fields from "hidden" to "shown" on login forms
 */
Array.prototype.map.call(document.querySelectorAll('.show-hide-password'), el => {
  el.addEventListener('click', e => {
    e.preventDefault();
    const formGroup = el.parentNode;
    const field = formGroup.querySelector('input');
    if (field.type === 'password') {
      field.type = 'text';
      // eslint-disable-next-line no-param-reassign
      el.querySelector('.show-hide-word').textContent = 'Hide';
      el.querySelector('.eye-icon').classList.replace('fa-eye-slash', 'fa-eye')
    } else {
      field.type = 'password';
      el.querySelector('.eye-icon').classList.replace('fa-eye', 'fa-eye-slash')
      el.querySelector('.show-hide-word').textContent = 'Show';
    }
  });
});
